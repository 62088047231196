export const state = () => ({
  units: [
    { key: 'price', value: 'GBP' },
    { key: 'priceEUR', value: 'EUR' },
    { key: 'priceUSD', value: 'USD' },
    { key: 'weight', value: 'g' },
    { key: 'size', value: 'cm' }
  ],
  currencies: []
})

export const mutations = {
  setCurrencies(state, currencies) {
    state.currencies = currencies
  },
  setUnit(state, unit) {
    const index = state.units.findIndex((x) => x.key === unit.key)
    if (index > -1) {
      state.units[index].value = unit.value
    } else {
      state.units.push({ ...unit })
    }
  }
}

export const getters = {
  unit(state) {
    return (key) => {
      const unit = state.units.find((x) => x.key === key)
      if (unit) {
        return unit.value
      }
      return null
    }
  },
  currency(state) {
    return (id) => {
      const currency = state.currencies.find((x) => x.id === id)
      if (currency) {
        return currency
      }
      return null
    }
  },
  currencies(state) {
    return state.currencies
  },
  currencyCodes(state) {
    return state.currencies.map((x) => x.code)
  }
}

export const actions = {
  fetchCurrencies({ commit }) {
    return this.$axios.$get('admin/currencies').then((response) => {
      commit('setCurrencies', response.data)
    })
  },
  setUnit({ commit }, unit) {
    commit('setUnit', unit)
  }
}
