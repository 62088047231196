// To use this crud mutations, state object supposed to have
// `items` property as an array.
export const CRUDMutations = (defaultItem) => ({
  add(state, item) {
    state.items = [...state.items, defaultItem(item)]
  },

  set(state, item) {
    state.item = item
  },

  update(state, item) {
    const items = [...state.items]
    const found = items.findIndex((x) => x.id === item.id)

    if (found < 0) {
      return false
    }

    items[found] = {
      ...state.items[found],
      ...item
    }

    state.items = [...items]
  },

  remove(state, item) {
    const { items } = state

    const found = items.findIndex((x) => x.id === item.id)

    if (found < 0) {
      return false
    }

    items.splice(found, 1)
    state.items = [...items]
  },

  // Loads only when array is empty
  load(state, items) {
    state.items = [...items]
  },

  clear(state) {
    state.items = []
  }
})
