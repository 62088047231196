<template>
  <div class="flex items-center">
    <nuxt-link
      v-if="to"
      :to="to"
      :class="
        active
          ? 'hover:text-gray-900 text-gray-700'
          : 'hover:text-gray-600 text-gray-500'
      "
      class="pr-2 trans-hover"
    >
      <slot />
    </nuxt-link>
    <span
      v-else
      :class="active ? 'text-gray-700' : 'text-gray-500'"
      class="pr-2 trans-hover"
    >
      <slot />
    </span>
    <i v-if="!active" class="fas fa-chevron-right text-gray-600 text-xs mr-2" />
  </div>
</template>

<script>
export default {
  props: {
    to: {
      type: String,
      default: ''
    },
    active: {
      type: Boolean,
      default: false
    }
  }
}
</script>
