import Vue from 'vue'
import frag from 'vue-frag'
import CKEditor from 'ckeditor4-vue'
import VueTippy from 'vue-tippy'
import VueExcelEditor from 'vue-excel-editor'

import Datepicker from '@/components/Datepicker'
import Form from '@/components/Form'
import Button from '@/components/Button'
import Input from '@/components/Input'
import PriceInput from '@/components/PriceInput'
import WeightInput from '@/components/WeightInput'
import Checkbox from '@/components/Checkbox'
import Radio from '@/components/Radio'
import FormGroup from '@/components/FormGroup'
import Icon from '@/components/Icon'
import Modal from '@/components/Modal'
import Panel from '@/components/Panel'
import PanelDivider from '@/components/PanelDivider'
import Popover from '@/components/Popover'
import Select from '@/components/Select'
import PageHeader from '@/components/PageHeader'
import Label from '@/components/Label'
import BooleanLabel from '@/components/BooleanLabel'
import Table from '@/components/Table'
import Toggle from '@/components/Toggle'
import DcSelect from '@/components/DcSelect'
import TextEditor from '@/components/TextEditor'
import OrderAddress from '@/components/OrderAddress'
import Textarea from '@/components/Textarea'
import Loading from '@/components/Loading'

Vue.directive('frag', frag)
Vue.use(CKEditor)
Vue.use(VueTippy, {
  arrow: true,
  animation: 'scale'
})
Vue.use(VueExcelEditor)
Vue.component('Form', Form)
Vue.component('FormGroup', FormGroup)
Vue.component('Button', Button)
Vue.component('Input', Input)
Vue.component('PriceInput', PriceInput)
Vue.component('WeightInput', WeightInput)
Vue.component('Checkbox', Checkbox)
Vue.component('Radio', Radio)
Vue.component('Icon', Icon)
Vue.component('Modal', Modal)
Vue.component('Panel', Panel)
Vue.component('PanelDivider', PanelDivider)
Vue.component('Popover', Popover)
Vue.component('Select', Select)
Vue.component('PageHeader', PageHeader)
Vue.component('Label', Label)
Vue.component('BooleanLabel', BooleanLabel)
Vue.component('Table', Table)
Vue.component('Toggle', Toggle)
Vue.component('Datepicker', Datepicker)
Vue.component('DcSelect', DcSelect)
Vue.component('TextEditor', TextEditor)
Vue.component('OrderAddress', OrderAddress)
Vue.component('Textarea', Textarea)
Vue.component('Loading', Loading)
