export default {
  mounted() {
    document.addEventListener('keydown', this.preventSubmit)
  },
  destroyed() {
    document.removeEventListener('keydown', this.preventSubmit)
  },
  methods: {
    preventSubmit(event = null) {
      const node = event.target || event.srcElement || null
      if (event.keyCode === 13 && node.type !== 'submit') {
        event.preventDefault()
      }
    }
  }
}
