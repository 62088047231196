<template>
  <div
    :class="[
      {
        'bg-gray-100': readOnly,
        'bg-gray-200': disabled,
        'bg-white': !readOnly && !disabled
      }
    ]"
    :style="error ? 'border: 1px solid #c20202 !important' : theme.input"
    class="flex items-center border rounded border-solid w-full border-gray-300"
  >
    <slot name="left" />
    <div
      v-if="icon"
      class="flex items-center flex-shrink-0"
      :style="(theme.icon, 'padding-left: 1rem;')"
    >
      <i :class="[`fa-${icon}`, iconSet]" class="text-md" />
    </div>
    <div class="flex-grow">
      <input
        ref="input"
        v-bind="$attrs"
        v-debounce="(val) => $emit('debounce', val)"
        :disabled="disabled"
        :readOnly="readOnly"
        class="w-full p-2 bg-transparent focus:outline-none h-10 text-sm"
        v-on="inputListeners"
      />
    </div>
    <slot name="right" />
  </div>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: null
    },
    icon: {
      type: String,
      default: null
    },
    iconSet: {
      type: String,
      default: 'fad'
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    error: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    inputListeners() {
      const self = this
      return {
        ...this.$listeners,
        input(e) {
          self.$emit('input', e.target.value)
        }
      }
    },
    theme() {
      return {
        input: '',
        icon: 'color: black;'
      }
    }
  },
  methods: {
    focus() {
      setTimeout(() => {
        this.$refs.input.focus()
      }, 200)
    }
  }
}
</script>
