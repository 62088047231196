import { CRUDMutations } from '@/utils/store-utilities'

// import axios from '@nuxtjs/axios'
let lastIndex = 0
const defaultItem = (item) => ({
  message: 'Message',
  duration: 0,
  dismissable: true,
  type: 'default', // default, success, danger, custom
  ...item,
  id: ++lastIndex
})

export const state = () => ({
  items: []
})

export const mutations = {
  ...CRUDMutations(defaultItem)
}

export const getters = {
  list(state) {
    return state.items
  },
  reversed(state) {
    return [...state.items].reverse()
  }
}

export const actions = {
  addNotification({ commit }, item) {
    if (typeof item === 'string') {
      commit('add', { message: item })
    } else {
      commit('add', { ...item })
    }
  },

  catchNotification({ commit }, error) {
    let message = 'Ooppps! Something wrong.'
    let duration = 3000
    if (typeof error === 'object') {
      message = error[0][0]
      error.forEach((element) => {
        message = element[0]

        duration += 2000
        commit('add', {
          message,
          type: 'error',
          duration
        })
      })
    } else if (typeof error === 'string') {
      message = error
      duration = 6000
    }

    if (typeof error !== 'object') {
      commit('add', {
        message,
        type: 'error',
        duration
      })
    }
  },

  dismissNotification({ commit }, item) {
    commit('remove', { ...item })
  },

  fetchAllNotifications({ commit }) {
    commit('load')
  },

  fetchUnreadNotifications({ commit }) {
    commit('load')
  },

  clearNotifications({ commit }) {
    commit('clear')
  }
}
