var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Popover',_vm._b({ref:"popover",attrs:{"position":_vm.position,"content-position":_vm.contentPosition,"container-class":_vm.containerClass},on:{"show":_vm.onShow,"hide":function($event){return _vm.$emit('hide')}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var open = ref.open;
return [_vm._t("selected",[_c('div',{staticClass:"border border-solid py-1 px-2 h-10 flex items-center",class:[
          {
            'text-gray-700 bg-white border-gray-300 hover:border-black transition rounded-lg':
              _vm.theme === 'light',
            'text-white bg-transparent border-transparent rounded-lg':
              _vm.theme === 'dark',
            'text-gray-700 bg-transparent border-transparent rounded-r':
              _vm.theme === 'no-border',
            'w-36': !_vm.noLimitWidth && _vm.theme !== 'no-border'
          }
        ],style:(_vm.selected || _vm.selectedItems.length > 1
            ? 'background-color: #D9D9D9 !important;'
            : ''),on:{"click":open}},[(!_vm.selected || _vm.selectedItems.length)?_c('span',{staticClass:"flex-grow text-black truncate",domProps:{"textContent":_vm._s(
            _vm.selectedItems.length >= 1
              ? (_vm.placeholder + " (" + (_vm.selectedItems.length) + ")")
              : _vm.placeholder
          )}}):_c('span',{staticClass:"flex flex-grow items-center justify-start"},[(_vm.selected.icon)?_c('span',{class:[_vm.iconSet, ("fa-" + (_vm.selected.icon)), 'mr-2']}):_vm._e(),_vm._v(" "),(_vm.selected.image)?_c('img',{staticClass:"h-3 mr-1 w-4",attrs:{"src":_vm.selected.image,"alt":_vm.$t('ApiMultipleSelect.image', { label: _vm.selected.label })}}):_vm._e(),_vm._v(" "),_c('span',{staticStyle:{"white-space":"nowrap"}},[_vm._v("\n            "+_vm._s(_vm._f("truncateText")(_vm.selected.label,_vm.theme === 'no-border' ? 5 : 12))+"\n          ")])]),_vm._v(" "),_c('i',{staticClass:"text-xs flex-shrink-0 ml-2",class:[_vm.iconSet, ("fa-" + _vm.icon)]})])],null,{ selected: _vm.selected })]}},{key:"content",fn:function(ref){
          var open = ref.open;
          var close = ref.close;
return [_c('div',{staticClass:"text-gray-700"},[(_vm.searchable)?_c('div',{staticClass:"p-1"},[_c('Input',{ref:"input",attrs:{"placeholder":_vm.$t('ApiMultipleSelect.search'),"icon":_vm.loading ? 'spinner-third fa-spin' : 'search',"type":"text","autofocus":""},on:{"focus":open,"blur":close,"keyup":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return close($event)},_vm.debouncedSearch],"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.search($event)}},model:{value:(_vm.query),callback:function ($$v) {_vm.query=$$v},expression:"query"}})],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"overflow-auto mt-1 px-1",style:({ maxHeight: '450px', minWidth: '150px' })},[(_vm.allowEmpty)?_c('div',{staticClass:"trans-hover py-2 px-3 hover:bg-gray-200 rounded focus:outline-none truncate cursor-pointer",on:{"click":function($event){return _vm.select({ value: null, label: _vm.placeholder })}}},[_vm._v("\n          "+_vm._s(_vm.placeholder)+"\n        ")]):_vm._e(),_vm._v(" "),_vm._l((_vm.displayedOptions),function(option,index){return _c('div',{key:index,staticClass:"trans-hover flex items-center py-2 px-3 hover:bg-gray-100 focus:outline-none truncate cursor-pointer relative",class:_vm.searchMultiSelect ? 'multi-select-item' : 'single-select-item',style:((_vm.selectedItems.length &&
              _vm.selectedItems.some(function (item) { return item === option.value; })) ||
            (_vm.selected && _vm.selected.value === option.value)
              ? 'background-color: #D9D9D9;'
              : ''),on:{"click":function($event){_vm.searchMultiSelect ? _vm.multiSelect(option) : _vm.select(option)}}},[_vm._t("option",[(_vm.searchMultiSelect)?_c('Checkbox',{attrs:{"value":_vm.selectedItems.some(function (item) { return item === option.value; })},on:{"click":function($event){return _vm.multiSelect(option)}}}):_vm._e(),_vm._v(" "),(option.icon)?_c('Icon',{attrs:{"name":option.icon,"set":_vm.iconSet}}):_vm._e(),_vm._v(" "),(option.image)?_c('img',{staticClass:"w-4 h-3 mr-2 object-cover",attrs:{"src":option.image,"alt":_vm.$t('ApiMultipleSelect.image', { label: option.label })}}):_vm._e(),_vm._v(" "),_c('span',{class:{ 'ml-2': option.icon }},[_vm._v("\n              "+_vm._s(option.label)+"\n            ")])],null,{ option: option, index: index })],2)}),_vm._v(" "),(!_vm.filterOptions || !_vm.filterOptions.length)?_c('div',{staticClass:"flex justify-center mt-1 py-2 text-gray-500 text-sm"},[_vm._v("\n          "+_vm._s(_vm.$t('messages.no_result'))+"\n        ")]):_vm._e()],2)])]}}],null,true)},'Popover',_vm.$attrs,false))}
var staticRenderFns = []

export { render, staticRenderFns }