export const state = () => ({
  id: null
})

export const mutations = {
  show(state, id) {
    state.id = id
  },

  hide(state, id) {
    state.id = state.id === id ? null : state.id
  }
}

export const getters = {
  toggledId(state) {
    return state.id
  }
}

export const actions = {
  showDcSelect({ commit }, id) {
    commit('show', id)
  },
  hideDcSelect({ commit }, id) {
    commit('hide', id)
  }
}
