var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"frag",rawName:"v-frag"}]},[_c('div',{staticStyle:{"min-height":"100vh"}},[_c('table',{staticClass:"table overflow-x-scroll relative"},[_c('thead',[(_vm.toggle.filters)?_c('tr',[_c('td',{attrs:{"colspan":_vm.fullspan}},[_c('div',{staticClass:"flex flex-wrap w-full items-center "},[(_vm.queryOn)?_c('FormGroup',{staticClass:"flex-grow w-full mb-6"},[_c('div',{staticClass:"flex items-center",staticStyle:{"gap":"20px"}},[_c('div',{staticClass:"relative w-full"},[_c('Input',{staticClass:"text-sm",staticStyle:{"min-width":"300px","max-height":"40px"},attrs:{"placeholder":"Search","icon":"search","iconset":"fas","color":"black"},on:{"keydown":function($event){
var _obj;
if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.fetch(Object.assign({}, _vm.params,
                          ( _obj = {}, _obj[_vm.queryOn] = _vm.query, _obj )))},"input":_vm.debouncedSearch},model:{value:(_vm.query),callback:function ($$v) {_vm.query=$$v},expression:"query"}}),_vm._v(" "),(_vm.searchFields.length)?_c('Select',{staticClass:"w-24 ml-2",staticStyle:{"position":"absolute !important","right":"0","top":"0"},attrs:{"options":_vm.searchFields,"placeholder":_vm.searchFields[0].label,"theme":"no-border"},model:{value:(_vm.searchField),callback:function ($$v) {_vm.searchField=$$v},expression:"searchField"}}):_vm._e()],1),_vm._v(" "),(_vm.filterValues.length)?_c('div',[_c('div',{staticClass:"flex",staticStyle:{"gap":"20px"}},[_vm._l((_vm.filterValues),function(filter,index){return _c('div',{directives:[{name:"frag",rawName:"v-frag"}],key:'filter-' + index},[_c('FormGroup',{attrs:{"name":filter.value}},[_c('Select',{ref:"searchSelect",refInFor:true,staticClass:"text-sm",attrs:{"placeholder":filter.placeholder,"options":filter.options,"value":_vm.params[filter.value] || null,"searchable":filter.isSearch,"search-multi-select":filter.searchMultiSelect,"filter-value":filter.value,"filter-values":_vm.filterValues,"close-on-select":false},on:{"select":function($event){return _vm.handleSelect(filter, $event)}}})],1)],1)}),_vm._v(" "),_c('Button',{staticClass:" border border-1 border-gray-300 bg-gray-600 text-gray-100 hover:text-gray-600 hover:border-gray-200 hover:bg-gray-200 transition-all",staticStyle:{"white-space":"nowrap","border-radius":"0.5rem !important","top":"55px","right":"7.5px"},attrs:{"shade":"500","disabled":!_vm.isFilterActive},on:{"click":_vm.clearSearch}},[_vm._v("\n                        Clear Filters\n                      ")])],2)]):_vm._e()])]):_vm._e(),_vm._v(" "),(_vm.$scopedSlots.filters)?_vm._t("filters",null,null,{
                  fetch: _vm.fetch,
                  query: _vm.query,
                  params: _vm.slotParams
                }):_vm._e()],2)])]):_vm._e(),_vm._v(" "),_c('tr',_vm._l((_vm.headers),function(item,key){return _c('th',{key:key},[(item === ' ')?_c('Checkbox',{on:{"click":function($event){return _vm.$emit('selectAll')}}}):_vm._e(),_vm._v(" "),(item === '#')?_c('Icon',{attrs:{"name":"hashtag"}}):[_vm._v("\n              "+_vm._s(item)+"\n            ")]],2)}),0)]),_vm._v(" "),_c('tbody',{staticClass:"text-sm"},[(_vm.rows.length < 1)?_c('tr',{staticClass:"table-no-hover"},[_c('td',{staticClass:"text-center text-gray-700",attrs:{"colspan":_vm.fullspan}},[(_vm.fetched === 0)?_c('div',[_vm._v("\n              "+_vm._s(_vm.$t('messages.loading_please_wait'))+"\n            ")]):(_vm.fetched === -1)?_c('div',{staticClass:"mb-36"},[_vm._v("\n              "+_vm._s(_vm.$t('messages.connection_error'))+"\n            ")]):_c('div',{staticClass:"mb-36"},[(_vm.query !== '')?_c('div',[_vm._v("\n                "+_vm._s(_vm.$t('messages.no_result'))+"\n              ")]):_c('div',[_vm._v("\n                "+_vm._s(_vm.$t('messages.no_data'))+"\n              ")])])])]):_vm._e(),_vm._v(" "),_vm._l((_vm.rows),function(row,rindex){return _c('tr',{key:rindex},[_vm._l((_vm.headers),function(item,key){return _c('td',{key:(key + "-" + rindex),on:{"click":function($event){key != 'selection' ? _vm.$emit('row', row) : function () {}}}},[_vm._t(("col:" + key),[_vm._v("\n              "+_vm._s(key === 'phone' ? _vm.cleanPhone(row['phone']) : row[key])+"\n            ")],null,{
                item: row,
                column: row[key],
                itemIndex: rindex,
                params: _vm.slotParams
              })],2)}),_vm._v(" "),(_vm.$scopedSlots.actions)?_c('td',{staticClass:"table-action-col"},[_vm._t("actions",null,null,{ item: row, params: _vm.slotParams })],2):_vm._e()],2)})],2),_vm._v(" "),_c('tfoot',[_c('tr',[_c('td',{attrs:{"colspan":_vm.fullspan - 1}},[(_vm.from && _vm.to && _vm.total)?_c('div',{staticClass:"flex justify-center"},[_vm._v("\n              Page "+_vm._s(_vm.currentPage)+", "+_vm._s(_vm.from)+"-"+_vm._s(_vm.to)+" of "+_vm._s(_vm.total)+"\n            ")]):_vm._e()])]),_vm._v(" "),_c('tr',[_c('td',{staticClass:"w-full",attrs:{"colspan":_vm.fullspan - 1}},[(_vm.pages.length > 1)?_c('div',{staticClass:"flex justify-center",staticStyle:{"gap":"0.5rem"}},[(_vm.currentPage >= 8)?_c('button',{class:[
                  'trans-hover',
                  'w-10 h-10',
                  'flex items-center justify-center',
                  'text-center rounded-lg',
                  'border border-solid border-gray-400 hover:border-black',
                  'bg-white',
                  'focus:outline-none'
                ],on:{"click":function($event){
                var _obj;
return _vm.fetch(Object.assign({}, _vm.params,
                    {page: 1},
                    ( _obj = {}, _obj[_vm.queryOn] = _vm.query || undefined, _obj )))}}},[_vm._v("\n                <<\n              ")]):_vm._e(),_vm._v(" "),_vm._l((_vm.pages),function(pg){return _c('button',{key:pg,class:[
                  'trans-hover',
                  'w-10 h-10',
                  'flex items-center justify-center',
                  'text-center rounded-lg',
                  'border border-solid border-gray-400 hover:border-black',
                  'bg-white',
                  'focus:outline-none',
                  {
                    'bg-gray-300': pg === _vm.currentPage
                  }
                ],on:{"click":function($event){
                var _obj;
return _vm.fetch(Object.assign({}, _vm.params,
                    {page: pg},
                    ( _obj = {}, _obj[_vm.queryOn] = _vm.query || undefined, _obj )))}}},[_vm._v("\n                "+_vm._s(pg)+"\n              ")])}),_vm._v(" "),(_vm.currentPage <= _vm.meta.last_page - 7)?_c('button',{class:[
                  'trans-hover',
                  'w-10 h-10',
                  'flex items-center justify-center',
                  'text-center rounded-lg',
                  'border border-solid border-gray-400 hover:border-black',
                  'bg-white',
                  'focus:outline-none'
                ],on:{"click":function($event){
                var _obj;
return _vm.fetch(Object.assign({}, _vm.params,
                    {page: _vm.meta.last_page},
                    ( _obj = {}, _obj[_vm.queryOn] = _vm.query || undefined, _obj )))}}},[_vm._v("\n                >>\n              ")]):_vm._e()],2):_vm._e()])])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }