import { CRUDMutations } from '@/utils/store-utilities'
// import axios from '@nuxtjs/axios'

const defaultItem = (item) => ({
  title: '',
  description: '',
  relatedProducts: [],

  // SEO
  slug: '',
  tags: [],

  // Images
  media_id: null, // Should be pickd
  second_media_id: null, // from ImageLoader.
  gallery: [],

  // Inventory
  sku: '',
  barcode: '',
  quantity: 0, // make { location: quantity } kv pair object.
  track_quantity: false,
  sell_out_of_stock: false,

  // Locations
  locations: [],
  location_id: '',

  // Pricing
  price: 0, // Valid
  charge_tax: false,

  // Variants
  has_variants: false,
  productVariants: [],

  // Shipping
  is_physical: true,
  weight: 0,
  hs_code: '',

  // Organization
  product_type_id: null,
  product_label_id: null,
  vendor_id: null,
  collections: [],
  status: 0,
  ...item
})

export const state = () => ({
  items: []
})

export const mutations = {
  ...CRUDMutations(defaultItem)
}

export const getters = {
  list(state) {
    return state.items
  },
  reversed(state) {
    return [...state.items].reverse()
  }
}

export const actions = {
  async getProduct({ commit }, id) {
    const item = await this.$axios.$get(`admin/products/${id}`)

    commit('set', item.data)
  },
  async createProduct({ commit }, collection) {
    const created = await this.$axios.$post('admin/products/create', {
      ...defaultItem(collection)
    })

    commit('add', defaultItem(created.data))
    return created
  },
  async updateProduct({ commit }, collection) {
    const update = await this.$axios.$put(`admin/products/${collection.id}`, {
      ...defaultItem(collection)
    })

    commit('update', defaultItem(update.data))
    return update
  },
  async removeProduct({ commit }, collection) {
    const remove = await this.$axios.$delete(`admin/products/${collection.id}`)

    if (remove.data) {
      commit('remove', collection)
    }
    return remove
  },
  async fetchProducts({ commit }, params = {}) {
    const list = await this.$axios.$get('admin/products', { params })
    if (list && list.data && list.data.length) {
      commit('load', list.data.map(defaultItem))
      return
    }

    commit('clear')
  },
  fetchProduct({ commit }, id) {
    return this.$axios.$get(`admin/products/${id}`).then((response) => {
      return response.data
    })
  }
}
