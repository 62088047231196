<template>
  <div
    :class="{ 'is-on': value }"
    class="Toggle rounded"
    @click="$emit('input', !value)"
  >
    <div class="Toggle-rail">
      <div class="Toggle-part Toggle-part-true">
        <slot name="true">{{ label.true }}</slot>
      </div>
      <div class="Toggle-handle rounded" />
      <div class="Toggle-part Toggle-part-false">
        <slot name="false">{{ label.false }}</slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Toggle',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    label: {
      type: Object,
      default() {
        return {
          true: this.$t('form.option.yes') || 'Yes',
          false: this.$t('form.option.no') || 'No'
        }
      }
    }
  }
}
</script>

<style lang="scss">
.Toggle {
  $size: 24px;
  $animation-duration: 200ms;
  position: relative;
  overflow: hidden;
  width: $size * 2.75;
  height: $size + 2px;
  background-color: theme('colors.white');
  user-select: none;
  border: 1px solid theme('colors.red.400');
  cursor: pointer;
  font-size: 10px;
  transition-property: border;
  transition-duration: $animation-duration;

  &.is-on {
    border: 1px solid theme('colors.green.400');

    .Toggle-handle {
      &::before,
      &::after {
        background-color: theme('colors.green.400');
      }
    }
    .Toggle-rail {
      transform: translateX(0);
    }
    .Toggle-part {
      &-true {
        transform: translateX(0);
        z-index: 1;
        opacity: 1;
      }
      &-false {
        // prettier-ignore
        transform: translateX(- calc($size / 2));
        z-index: 0;
        opacity: 0;
      }
    }
  }

  &-rail {
    position: absolute;
    display: flex;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    transform: translateX(calc(-100% - #{-$size}));
    transition-property: transform;
    transition-duration: $animation-duration;
  }

  &-part {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 auto;
    width: calc(100% - calc($size / 2));
    color: theme('colors.white');
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 0.5px;
    transition-property: transform, opacity;
    transition-duration: $animation-duration;

    &-true {
      transform: translateX(calc($size / 2));
      padding-right: 12px;
      background-color: theme('colors.green.600');
      z-index: 0;
      opacity: 0;
    }
    &-false {
      transform: translateX(0);
      padding-left: 12px;
      background-color: theme('colors.red.600');
      z-index: 1;
      opacity: 1;
    }
  }

  &-handle {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3;
    top: 0;
    left: calc(100% - calc($size / 2));
    transform: translateX(-50%);
    width: $size;
    height: $size;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.4);
    background-color: theme('colors.white');

    &::before,
    &::after {
      content: '';
      display: block;
      width: 3px;
      height: calc($size / 1.75);
      box-shadow: inset 1px 1px 1px rgba(0, 0, 0, 0.2);
      background-color: theme('colors.red.400');
      transition-property: background-color;
      transition-duration: $animation-duration;
    }

    &::before {
      margin-right: 3px;
    }
  }
}
</style>
