<template>
  <div v-bind="$attrs" :class="{ 'z-10': show }" class="relative">
    <div
      ref="trigger"
      :class="{
        'rounded-b-none': show,
        'opacity-50 cursor-not-allowed': disabled
      }"
      class="bg-white border border-gray-300 border-solid flex h-10 items-center p-2 rounded text-gray-700"
    >
      <div v-if="!multiple && !show && isNotEmpty" :class="customClassContent">
        <slot v-bind="{ item: selectedItems[0] }" name="before" />
      </div>

      <input
        v-show="!show"
        :value="inputValue"
        :disabled="loading || disabled"
        :placeholder="placeholder"
        type="text"
        class="w-full h-full outline-none text-gray-700"
        @focus="showUp"
        @click="showUp"
      />

      <div v-if="!show && isNotEmpty" :class="customClassContent">
        <slot v-bind="{ item: selectedItems[0] }" name="after" />
      </div>

      <div
        v-if="selectInModalYTC"
        v-show="show"
        class="bg-gray-100 fixed flex h-12 items-center justify-center right-0 lg:right-10 rounded-full top-0 lg:top-10 w-12 z-50"
      >
        <Button
          class="text-gray-600 hover:text-gray-700 w-10 h-10 p-0 justify-center"
          color="empty"
          size="empty"
          icon="times"
          icon-set="fas"
          @click="close"
        />
      </div>

      <div
        v-if="selectInModalYTC"
        v-show="show"
        class="select-in-modal-search-container"
      >
        <input
          ref="searchInput"
          v-model="query"
          v-debounce="search"
          class="w-full h-full outline-none text-gray-700"
          type="text"
          placeholder="Search..."
          @keydown.esc.self.prevent="close"
          @keyup="keyCreate"
        />
      </div>

      <input
        v-else
        v-show="show"
        ref="searchInput"
        v-model="query"
        v-debounce="search"
        class="w-full h-full outline-none text-gray-700"
        type="text"
        placeholder="Search..."
        @keydown.esc.self.prevent="close"
        @keyup="keyCreate"
      />

      <button
        v-if="!show && isNotEmpty && hasClear"
        :disabled="loading || disabled"
        type="button"
        class="flex h-full trans-hover hover:text-gray-700 focus:outline-none items-center justify-center ml-2 text-gray-500 text-xs"
        @click="clear"
      >
        <i class="fas fa-times" />
      </button>

      <button
        :disabled="loading || disabled"
        type="button"
        class="flex h-full trans-hover hover:text-gray-700 focus:outline-none items-center justify-center ml-2 text-gray-500 text-xs"
        @click="toggle"
      >
        <i
          :class="{
            'fa-spinner-third fa-spin': loading,
            'fa-chevron-up': !loading && show,
            'fa-chevron-down': !loading && !show
          }"
          class="fas"
        />
      </button>
    </div>

    <div
      v-if="selectInModalYTC && show"
      class="bg-black fixed h-screen left-0 opacity-50 top-0 trans-fade w-screen z-0"
    ></div>

    <div
      v-if="selectInModalYTC && loading"
      :class="{ 'max-h-64 opacity-100': show, 'max-h-0 opacity-0': !show }"
      class="bg-white border border-solid border-t-0 overflow-scroll rounded-b rounded-t-none w-full mb-2"
      :style="popoverStyle"
      @keydown.esc.self.prevent="close"
    >
      <div class="flex items-center justify-center p-4 w-full">
        <i class="fas fa-spinner-third fa-spin text-primary" />
      </div>
    </div>

    <div
      v-else
      v-show="show"
      :class="{ 'max-h-64 opacity-100': show, 'max-h-0 opacity-0': !show }"
      class="bg-white border border-solid border-t-0 overflow-scroll rounded-b rounded-t-none w-full mb-2"
      :style="popoverStyle"
      @keydown.esc.self.prevent="close"
    >
      <button
        v-if="create && query && !queryHasInItems"
        :disabled="loading"
        class="border-b border-gray-200 border-solid flex trans-hover hover:bg-gray-200 focus:outline-none items-center justify-start p-2 w-full"
        type="button"
        @click="onCreate"
      >
        <i class="fas fa-plus mr-2" />
        {{ $t('DcSelect.create_x_and_select', { x: query }) }}
      </button>

      <button
        v-for="(item, index) in items"
        :key="item.id || index"
        :disabled="!canAddItem(item)"
        :class="{
          'bg-gray-100': !canAddItem(item),
          hidden: hideDisabled && isDisabled(item)
        }"
        class="text-left border-b border-gray-200 border-solid flex trans-hover hover:bg-gray-200 focus:outline-none items-center justify-start p-2 w-full"
        type="button"
        @click="select(item)"
      >
        <slot v-bind="{ item, index }" name="option">
          <slot v-bind="{ item }" name="before" />

          <div class="flex items-center justify-start gap-2">
            <Thumbnail :item="item" />

            <div class="flex flex-col justify-center items-start">
              <div class="flex items-center gap-1">
                <span>{{ item[labelKey] }}</span>

                <span
                  v-if="item.parents && item.parents.length"
                  class="text-gray-500 text-xs"
                  >({{
                    item.parents.map((parent) => parent.name).toString()
                  }})</span
                >

                <span v-if="item.email" class="text-gray-500 text-xs">
                  {{ item.email }}
                </span>
              </div>

              <div class="flex gap-2 text-gray-600 text-sm">
                <div v-if="item.id">ID: {{ item.id }}</div>

                <div v-if="item.sku">SKU: {{ item.sku }}</div>

                <div v-if="item.parent">Parent: {{ item.parent }}</div>

                <div v-if="item.quantity">Quantity: {{ item.quantity }}</div>

                <div v-if="item.productVariants">
                  Variants: {{ item.productVariants.length }}
                </div>

                <div v-if="item.price">Price: {{ item.price }} GBP</div>
              </div>
            </div>
          </div>

          <slot v-bind="{ item }" name="after" />
        </slot>
      </button>

      <button
        v-if="hasMore"
        :disabled="morePageLoading"
        type="button"
        class="load-more-button w-full p-4 text-primary-light text-center flex items-center justify-center hover:bg-gray-200"
        @click.prevent="search(query, true)"
      >
        <Icon v-if="morePageLoading" name="spinner-third" class="fa-spin" />
        <span v-else class="load-more-button">Load more</span>
      </button>

      <div v-if="!items.length" class="flex p-2 text-gray-600 w-full">
        {{ $t('DcSelect.not_found') }}
      </div>
    </div>

    <div v-if="multiple && isNotEmpty && !hideSelections">
      <draggable
        v-if="draggable"
        :list="selectedItems"
        handle=".handle"
        ghost-class="bg-gray-200"
        @end="
          $emit(
            'input',
            selectedItems.map((x) => x[valueKey])
          )
        "
      >
        <slot
          v-for="(selectedItem, index) in selectedItems"
          :selected="selectedItem"
          :index="index"
          :remove="remove"
          name="selected"
        >
          <div
            :key="selectedItem.id || index"
            class="bg-primary-light flex items-center justify-center mr-2 mt-2 px-2 py-1 rounded shadow text-white text-xs"
          >
            <Icon name="align-justify" class="handle cursor-move" />
            <slot v-bind="{ item: selectedItem }" name="before" />

            <span>{{ selectedItem[labelKey] }}</span>

            <slot v-bind="{ item: selectedItem }" name="after" />
            <button
              class="flex h-full items-center ml-2 focus:outline-none"
              type="button"
              @click="remove(selectedItem)"
            >
              <i class="fas fa-times" />
            </button>
          </div>
        </slot>
      </draggable>
      <div v-else class="flex flex-wrap" :class="customClassContent">
        <slot
          v-for="(selectedItem, index) in selectedItems"
          :selected="selectedItem"
          :index="index"
          :remove="remove"
          name="selected"
        >
          <div
            v-if="showInLinesSelected"
            :key="selectedItem.id || index"
            :class="{ 'border-t border-gray-200': index > 0 }"
            class="w-full flex items-center p-2 text-gray-700 hover:bg-gray-100 trans-hover"
          >
            <slot v-bind="{ item: selectedItem }" name="before">
              <span class="mr-2 text-sm" v-text="`${index + 1}.`" />
            </slot>

            <template
              v-if="selectedItem.parents && selectedItem.parents.length"
            >
              <span class="truncate text-sm">{{ selectedItem[labelKey] }}</span>
              <span class="truncate text-xs ml-2"
                >({{
                  selectedItem.parents.map((parent) => parent.name).toString()
                }})</span
              >
            </template>

            <span v-else class="truncate text-sm font-bold">
              {{ selectedItem[labelKey] }}

              <span
                v-if="selectedItem.firstParent"
                class="text-gray-500 font-thin"
              >
                in {{ selectedItem.firstParent }}
              </span>
            </span>

            <button
              class="p-2 flex h-full justify-center items-center ml-auto focus:outline-none"
              type="button"
              @click="remove(selectedItem)"
            >
              <i class="fal fa-times" />
            </button>
          </div>

          <div
            v-else
            :key="selectedItem.id || index"
            class="bg-primary-light flex items-center justify-center mr-2 mt-2 px-2 py-1 rounded shadow text-white text-xs"
          >
            <slot v-bind="{ item: selectedItem }" name="before" />

            <template
              v-if="selectedItem.parents && selectedItem.parents.length"
            >
              <span>{{ selectedItem[labelKey] }}</span>
              <span class="text-xs ml-2"
                >({{
                  selectedItem.parents.map((parent) => parent.name).toString()
                }})</span
              >
            </template>

            <span v-else>{{ selectedItem[labelKey] }}</span>

            <slot v-bind="{ item: selectedItem }" name="after" />
            <button
              class="flex h-full items-center ml-2 focus:outline-none"
              type="button"
              @click="remove(selectedItem)"
            >
              <i class="fas fa-times" />
            </button>
          </div>
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import draggable from 'vuedraggable'
import clickOutsideToggle from '@/mixins/click-outside-toggle.js'
import Thumbnail from './Thumbnail.vue'

export default {
  components: { Thumbnail, draggable },
  mixins: [clickOutsideToggle],
  props: {
    value: {
      type: [Number, Array, String],
      default: null
    },
    src: {
      type: String,
      default: null
    },
    options: {
      type: [Array, Object],
      default: null
    },
    valueKey: {
      type: String,
      default: 'id'
    },
    labelKey: {
      type: String,
      default: 'name'
    },
    idKey: {
      type: String,
      default: null
    },
    params: {
      type: Object,
      default: () => {}
    },
    createKeyCodes: {
      type: Array,
      default: () => []
    },
    create: {
      type: Function,
      default: null
    },
    placeholder: {
      type: String,
      default: null
    },
    searchKey: {
      type: String,
      default: 'name'
    },
    searchable: {
      type: Boolean,
      default: true
    },
    multiple: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    disabledItems: {
      type: Array,
      default: () => {}
    },
    autoSelectFirst: {
      type: Boolean,
      default: false
    },
    hasClear: {
      type: Boolean,
      default: true
    },
    draggable: {
      type: Boolean,
      default: false
    },
    hideSelections: {
      type: Boolean,
      default: false
    },
    customClassContent: {
      type: String,
      default: null
    },
    hideDisabled: {
      type: Boolean,
      default: false
    },
    selectInModal: {
      type: Boolean,
      default: false
    },
    showInLinesSelected: {
      type: Boolean,
      default: false
    },
    updateValueOnSelect: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      loading: false,
      query: null,
      items: [],
      selectedItems: [],
      scrollPosition: 0,
      popoverStyle: {},
      currentPage: 0,
      hasMore: false,
      morePageLoading: true
    }
  },
  computed: {
    queryHasInItems() {
      return this.items.some((e) => e.name === this.query)
    },
    inputValue() {
      if (!this.multiple && this.selectedItems.length) {
        return this.selectedItems[0][this.labelKey]
      }

      return ''
    },
    idParamKey() {
      if (this.idKey) {
        return this.idKey
      }

      return this.multiple ? `${this.valueKey}s` : this.valueKey
    },
    isNotEmpty() {
      return Array.isArray(this.value) && this.multiple
        ? this.value.length
        : this.value
    },
    filteredItems() {
      if (this.items) {
        return this.items
      }

      return []
    },
    selectInModalYTC() {
      return this.selectInModal && process.env.PROJECT === 'YTC'
    }
  },
  watch: {
    show: {
      handler(newValue, oldValue) {
        if (newValue && !oldValue) {
          if (this.$refs.searchInput) {
            setTimeout(() => this.$refs.searchInput.focus(), 500)
          }

          if (!this.filteredItems.length) {
            this.search()
          }
        }

        this.calculatePopoverStyle()
      }
    },
    value: {
      handler(newValue) {
        this.currentPage = 0
        this.hasMore = true

        if (newValue) {
          let hasDifferent = false
          if (Array.isArray(newValue)) {
            hasDifferent =
              this.selectedItems.length !== newValue.length ||
              this.selectedItems.filter(
                (m) => !newValue.includes(m[this.valueKey])
              ).length > 0
          } else {
            hasDifferent =
              !this.selectedItems.length ||
              !this.selectedItems.includes((m) => m[this.valueKey] === newValue)
          }

          if (hasDifferent) {
            this.fetch()
          }
        } else {
          this.clear()
        }
      }
    },
    params: {
      handler(newParams, oldParams) {
        if (JSON.stringify(newParams) !== JSON.stringify(oldParams)) {
          this.items = []
        }
      }
    }
  },
  mounted() {
    if (!this.src && this.options) {
      this.items = this.mappedOptions()
    }

    if (this.value || (!this.src && this.value !== null)) {
      this.fetch()
    }

    if (!this.value && this.autoSelectFirst) {
      this.search('').then(() => {
        this.selectFirst()
      })
    }

    window.addEventListener('scroll', this.handleScroll)
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    ...mapActions('notification', ['catchNotification']),
    search(query, loadMore = false) {
      return new Promise((resolve, reject) => {
        if (this.src) {
          if (loadMore === true) {
            this.morePageLoading = true
          } else {
            this.loading = true
          }

          const params = query
            ? {
                [this.searchKey]: query
              }
            : {}

          this.$axios
            .$get(this.src, {
              params: {
                ...params,
                ...this.params,
                page: loadMore === true ? this.currentPage + 1 : 1
              }
            })
            .then((response) => {
              if (loadMore === true) {
                this.items = this.items.concat(response.data)
              } else {
                this.items = response.data
              }

              resolve(this.items)

              if (response.meta) {
                this.currentPage = response.meta.current_page
                this.hasMore =
                  response.meta.current_page < response.meta.last_page
              } else {
                this.hasMore = false
              }
            })
            .catch((error) => {
              this.catchNotification(error)
              reject(error)
            })
            .finally(() => {
              this.loading = false
              this.morePageLoading = false
            })
        } else {
          this.items = this.mappedOptions().filter((m) => {
            const searchQuery = query === undefined ? '' : query
            return !m[this.labelKey]
              .toLowerCase()
              .search(searchQuery.toLowerCase())
          })

          resolve(this.items)
        }
      })
    },
    fetch() {
      return new Promise((resolve, reject) => {
        let fetchValues = true
        if (this.multiple) {
          if (Array.isArray(this.value)) {
            if (
              this.value[0] &&
              this.value[0][this.labelKey] &&
              this.value[0][this.valueKey]
            ) {
              const value = []
              this.value.forEach((m) => {
                this.selectedItems.push(m)
                value.push(m[this.valueKey])
              })

              this.$emit('input', value)
              fetchValues = false
            }
          } else if (
            this.value &&
            this.value[this.labelKey] &&
            this.value[this.valueKey]
          ) {
            this.selectedItems.push(this.value)
            this.$emit('input', [this.value[this.valueKey]])
          }
        } else if (
          this.value &&
          this.value instanceof Object &&
          this.value[this.labelKey] &&
          this.value[this.valueKey]
        ) {
          this.selectedItems.push(this.value)
          this.$emit('input', this.value[this.valueKey])
          fetchValues = false
        }

        if (fetchValues) {
          if (this.src) {
            if (this.multiple) {
              if (!this.value.length) {
                resolve()
                return
              }

              const params = {
                [this.idParamKey]: this.value,
                ...this.params
              }

              this.loading = false
              this.$axios
                .get(this.src, {
                  params
                })
                .then((response) => {
                  this.selectedItems = response.data.data
                  resolve()
                })
                .catch((error) => {
                  this.catchNotification(error)
                  reject(error)
                })
                .finally(() => {
                  this.loading = false
                })
            } else {
              if (!this.value) {
                resolve()
                return
              }

              this.loading = false
              this.$axios
                .get(`${this.src}/${this.value}`)
                .then((response) => {
                  this.selectedItems = [response.data.data]
                  resolve()
                })
                .catch((error) => {
                  this.catchNotification(error)
                  reject(error)
                })
                .finally(() => {
                  this.loading = false
                })
            }
          } else {
            this.selectedItems = this.mappedOptions().filter(
              (m) => m[this.valueKey].toString() === this.value.toString()
            )

            resolve()
          }
        } else {
          resolve()
        }
      })
    },
    showUp() {
      if (this.disabled) {
        return
      }

      this.open()
    },
    clear() {
      this.query = null
      this.selectedItems = []
      this.$emit('input', this.multiple ? [] : null)
    },
    hasItem(item) {
      return !!this.selectedItems.find(
        (m) => m[this.valueKey] === item[this.valueKey]
      )
    },
    select(item) {
      if (!this.hasItem(item) && !this.isDisabled(item)) {
        if (this.multiple) {
          this.selectedItems.push(item)

          const value = this.updateValueOnSelect
            ? this.value || []
            : Object.assign([], this.value)
          value.push(item[this.valueKey])

          this.$emit('input', value)
          this.$emit('item', item)
        } else {
          this.selectedItems = [item]
          this.$emit('input', item[this.valueKey])
          this.$emit('item', item)
        }
      }

      this.query = null
      this.close()
    },
    remove(item) {
      if (this.multiple) {
        const value = this.value.filter((m) => m !== item[this.valueKey])
        this.$emit('input', value)
        setTimeout(() => {
          this.selectedItems = this.selectedItems.filter((m) =>
            value.includes(m[this.valueKey])
          )
        }, 200)
      } else {
        this.$emit('input', null)
        setTimeout(() => {
          this.selectedItems = []
        }, 200)
      }
    },
    onCreate() {
      this.loading = true
      this.create(this.query)
        .then((item) => this.select(item))
        .finally(() => {
          this.loading = false
        })
    },
    keyCreate(event) {
      if (this.createKeyCodes.length > 0) {
        for (let index = 0; index < this.createKeyCodes.length; index++) {
          if (event.keyCode === this.createKeyCodes[index]) {
            if (this.createKeyCodes[index] !== 13) {
              this.loading = true
              this.create(this.query.slice(0, -1))
                .then((item) => this.select(item))
                .finally(() => {
                  this.loading = false
                })
            } else {
              this.onCreate()
            }
          }
        }
      }
    },
    mappedOptions() {
      if (Array.isArray(this.options)) {
        return this.options
      } else {
        const keys = Object.keys(this.options)
        const items = []
        keys.forEach((key) => {
          items.push({
            id: key,
            name: this.options[key]
          })
        })

        return items
      }
    },
    isDisabled(item) {
      return (
        this.disabledItems &&
        this.disabledItems.find((v) => {
          return v.toString() === item[this.valueKey].toString()
        })
      )
    },
    canAddItem(item) {
      return !this.loading && !this.hasItem(item) && !this.isDisabled(item)
    },
    selectFirst() {
      if (this.items && this.items.length) {
        this.select(this.items[0])
      }
    },
    handleScroll(event) {
      this.scrollPosition = event.target.scrollTop

      this.calculatePopoverStyle()
    },
    calculatePopoverStyle() {
      if (this.show) {
        if (this.selectInModalYTC) {
          this.popoverStyle = {
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '80%',
            maxHeight: '50%'
          }
        } else if (this.$refs.trigger) {
          // if element is near to end of the screen, show popover above the trigger
          if (
            window.innerHeight -
              this.$refs.trigger.getBoundingClientRect().bottom <
            300
          ) {
            this.popoverStyle = {
              position: 'fixed',
              top: `${this.$refs.trigger.getBoundingClientRect().bottom -
                (256 + 40)}px`,
              left: `${this.$refs.trigger.getBoundingClientRect().left}px`,
              width: `${this.$refs.trigger.getBoundingClientRect().width}px`
            }
          } else {
            this.popoverStyle = {
              position: 'fixed',
              top: `${this.$refs.trigger.getBoundingClientRect().bottom}px`,
              left: `${this.$refs.trigger.getBoundingClientRect().left}px`,
              width: `${this.$refs.trigger.getBoundingClientRect().width}px`
            }
          }
        } else {
          this.popoverStyle = {
            left: 0,
            position: 'absolute'
          }
        }
      } else {
        this.popoverStyle = {}
      }
    }
  }
}
</script>

<style lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

input:disabled,
button:disabled {
  cursor: not-allowed;
}

.max-h-64 {
  max-height: 16rem;
}

.max-h-0 {
  max-height: 0;
}

.trans-slide-fade {
  transition-property: max-height;
  transition-duration: 0.3s;
  transition-timing-function: ease;
}

.select-in-modal-search-container {
  position: fixed;
  top: 10%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  height: 40px;
  width: 50%;
  background: white;
  padding: 8px;
  min-width: 300px;
}
</style>
