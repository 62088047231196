<template>
  <div
    :class="{
      'relative z-50': isActive,
      'static z-0': !isActive
    }"
    tabindex="0"
    class="outline-none"
    @keydown.esc.self.prevent="close"
  >
    <div class="static inline-block outline-none" @click="open">
      <slot />
    </div>

    <div
      :class="[
        'trans-fade',
        'flex items-center justify-center',
        'w-screen h-screen',
        'overflow-auto',
        'fixed top-0 left-0',
        {
          'visible opacity-100': isActive,
          'invisible opacity-0': !isActive
        }
      ]"
    >
      <div
        class="fixed top-0 left-0 w-full h-full bg-black opacity-50 z-0"
        @click="close"
      />
      <div
        :class="[`max-w-${size}`]"
        class="relative w-full bg-white shadow rounded shadow-lg z-10"
      >
        <div class="flex w-full justify-center items-center pt-4 px-4">
          <div class="flex-grow font-bold text-xl text-gray-700">
            {{ title }}
          </div>

          <div class="ml-auto flex-shrink-0">
            <Button
              class="text-gray-600 hover:text-gray-700 hover:bg-gray-100 w-6 h-6 p-0 justify-center"
              color="empty"
              size="empty"
              icon="times"
              icon-set="fas"
              @click="close"
            />
          </div>
        </div>
        <div :class="[`p-${padding}`, 'text-gray-800']">
          <slot v-bind="{ open, close, toggle }" name="content" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { isComponent } from '../utils/component'

export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    size: {
      type: String,
      default: 'xl'
    },
    padding: {
      type: String,
      default: '4'
    },
    active: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isActive: this.active
    }
  },
  watch: {
    isActive: {
      handler(isActive) {
        if (isActive) {
          this.$emit('open')
        } else {
          this.$emit('close')
        }
      }
    }
  },
  mounted() {
    this.setFlatPanels(this)
  },
  methods: {
    toggle() {
      this.$data.isActive = !this.$data.isActive
    },
    open() {
      this.$data.isActive = true
    },
    close() {
      this.$data.isActive = false
    },
    setFlatPanels(vm) {
      if (vm) {
        vm.$children.forEach((child) => {
          if (isComponent(child, '<Panel>')) {
            child.isFlat = true
          } else {
            this.setFlatPanels(child)
          }
        })
      }
    }
  }
}
</script>
