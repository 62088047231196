<template>
  <div>
    <i
      v-if="name.length > 1"
      v-bind="$attrs"
      :class="[set, `fa-${name}`, { 'fa-fw': fixed }]"
    />
    <span
      v-else
      :class="{ 'fa-fw': fixed }"
      v-bind="$attrs"
      class="font-bold font-normal"
    >
      {{ name }}
    </span>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    name: {
      type: String,
      default: ''
    },
    set: {
      type: String,
      default: 'fas'
    },
    fixed: {
      type: Boolean,
      default: false
    }
  }
}
</script>
