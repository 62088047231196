<template>
  <div class="flex items-center w-full p-4 bg-white rounded-sm">
    <div>
      <h1 class="text-base text-gray-700 mb-1 text-lg">{{ title }}</h1>
      <Breadcrumb v-if="steps.length > 0" :steps="steps" />
    </div>
    <div class="ml-auto">
      <slot name="right" />
    </div>
  </div>
</template>

<script>
import Breadcrumb from '@/components/Breadcrumb'

export default {
  components: {
    Breadcrumb
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    steps: {
      type: Array,
      default: () => []
    }
  }
}
</script>
