<template>
  <ckeditor
    ref="editor"
    :value="value"
    :config="editorConfig"
    @input="(v) => $emit('input', v)"
    @ready="onReady"
  ></ckeditor>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: ''
    },
    editorConfig: {
      type: Object,
      default: () => ({
        extraPlugins: 'uploadimage',
        imageUploadUrl: process.env.API_URL + 'admin/media/create',
        // filebrowserBrowseUrl: '/media',
        filebrowserUploadUrl: process.env.API_URL + 'admin/media/create',
        // filebrowserWindowWidth: '500',
        // filebrowserWindowHeight: '400'
        allowedContent: true,
        versionCheck: false,
      })
    }
  },
  methods: {
    onInput(value) {
      this.$emit('input', value)
    },
    onReady(e) {
      this.$refs.editor.instance.on('fileUploadRequest', (e) => {
        const xhr = e.data.fileLoader.xhr

        const formData = new FormData()
        formData.append('file', e.data.fileLoader.data)
        formData.append('title', e.data.fileLoader.fileName)
        formData.append('resize', 0)

        Object.keys(this.$axios.defaults.headers.common).forEach((key) => {
          xhr.setRequestHeader(key, this.$axios.defaults.headers.common[key])
        })

        xhr.send(formData)
        e.stop()
      })

      this.$refs.editor.instance.on('fileUploadResponse', (e) => {
        e.stop()
        const response = JSON.parse(e.data.fileLoader.xhr.response)
        e.data.url = response.data.original.src
      })
    }
  }
}
</script>
