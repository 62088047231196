import { CRUDMutations } from '@/utils/store-utilities'

const defaultItem = (item) => ({
  ...item
})

export const state = () => ({
  items: []
})

export const mutations = {
  ...CRUDMutations(defaultItem)
}

export const getters = {}

export const actions = {
  addSlug({ commit }, item) {
    return this.$axios
      .$post('admin/slugs/create', { ...item })
      .then((response) => {
        commit('add', { ...response.data })
      })
  },
  removeSlug({ commit }, item) {
    return this.$axios.$delete(`admin/slugs/${item.id}`).then((response) => {
      if (response.data) {
        commit('remove', item)
      }
    })
  },
  updateSlug({ commit }, item) {
    return this.$axios
      .$put(`admin/slugs/${item.id}`, { ...item })
      .then((response) => {
        commit('update', { ...response.data })
      })
  },
  fetchSlugs({ commit }) {
    return this.$axios.$get('admin/slugs').then((response) => {
      commit('load', response.data)
    })
  },
  fetchSlug({ commit }, id) {
    return this.$axios.$get(`admin/slugs/${id}`).then((response) => {
      return response.data
    })
  }
}
