import { CRUDMutations } from '@/utils/store-utilities'

const defaultItem = (item) => ({
  ...item
})

export const state = () => ({
  items: []
})

export const mutations = {
  ...CRUDMutations(defaultItem)
}

export const getters = {
  fetchTheme: (state) => (id) => {
    if (state) {
      return state.items.find((item) => {
        return item.id === id
      })
    }
  }
}

export const actions = {
  addTheme({ commit }, item) {
    return this.$axios
      .$post('admin/themes/create', { ...item })
      .then((response) => {
        commit('add', { ...response.data })
      })
  },
  removeTheme({ commit }, id) {
    return this.$axios.$delete(`admin/themes/${id}`).then((response) => {
      if (response.data) {
        commit('remove', { id })
      }
    })
  },
  updateTheme({ commit }, item) {
    return this.$axios
      .$put(`admin/themes/${item.id}`, { ...item })
      .then((response) => {
        commit('update', { ...response.data })
      })
  },
  fetchTheme({ commit }, id) {
    return this.$axios.$get(`admin/themes/${id}`).then((response) => {
      return response.data
    })
  },
  fetchThemes({ commit }) {
    return this.$axios.$get('admin/themes').then((response) => {
      commit('load', response.data)
    })
  }
}
