export default {
  data() {
    return {
      show: false
    }
  },
  destroyed() {
    document.removeEventListener('click', this.closeOnClickOutside)
  },
  methods: {
    open() {
      if (this.show) return
      this.show = true
      document.addEventListener('click', this.closeOnClickOutside)
    },
    close() {
      if (!this.show) return
      this.show = false
      document.removeEventListener('click', this.closeOnClickOutside)
    },
    toggle() {
      this.show ? this.close() : this.open()
    },
    closeOnClickOutside(event) {
      // if target element has `load-more-button` class, don't close the dropdown
      if (event.target.classList.contains('load-more-button')) return

      if (!this.$el.contains(event.target)) {
        this.close()
      }
    }
  }
}
