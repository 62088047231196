<template>
  <label
    :class="disabled ? 'cursor-default' : 'cursor-pointer'"
    class="custom-radio flex mb-0"
  >
    <input
      v-bind="$attrs"
      :checked="value === selectedValue"
      :disabled="disabled"
      :value="selectedValue"
      type="radio"
      v-on="inputListeners"
    />
    <div
      :class="[{ danger, outline }, 'rounded-full']"
      class="checkmark w-4 h-4 flex items-center justify-center mr-2 flex-shrink-0 my-1"
    >
      <Icon :name="icon" class="mark" fixed />
    </div>
    <div class="radio-label">
      <slot />
    </div>
  </label>
</template>
<script>
export default {
  props: {
    icon: {
      type: String,
      default: 'circle'
    },
    danger: {
      type: Boolean,
      default: false
    },
    rounded: {
      type: Boolean,
      default: false
    },
    outline: {
      type: Boolean,
      default: false
    },
    value: {
      type: [String, Number, Boolean],
      default: false
    },
    selectedValue: {
      type: [String, Number, Boolean],
      default: undefined
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    inputListeners() {
      const self = this
      return {
        ...this.$listeners,
        input(e) {
          self.$emit('input', self.selectedValue)
        }
      }
    }
  }
}
</script>
<style lang="scss">
.custom-radio {
  input[type='radio'] {
    appearance: none;
  }

  .checkmark {
    flex-shrink: 0;
    flex-grow: 0;
    background-color: theme('colors.gray.300');
    transition-property: background-color;
    transition-duration: 250ms;
    transition-timing-function: ease-in-out;
    &.outline {
      border: 2px solid theme('colors.primary.light');
      background-color: transparent;

      &.danger {
        border: 2px solid theme('colors.red.600');
      }
    }
  }

  .radio-label {
    transition-property: color;
    transition-duration: 250ms;
    transition-timing-function: ease-in-out;
  }

  &:hover .checkmark {
    background-color: theme('colors.gray.400');
    &.outline {
      background-color: theme('colors.gray.200');
    }
  }

  .checkmark > .mark {
    color: theme('colors.white');
    opacity: 0;
    transition: opacity 250ms;
    transition-timing-function: ease-in-out;
    font-size: 8px;
  }

  input[type='radio']:checked + .checkmark {
    background-color: theme('colors.primary.light');

    &.danger {
      background-color: theme('colors.red.600');
    }

    .mark {
      opacity: 1;
    }
  }

  input[type='radio']:checked:disabled + .checkmark {
    background-color: theme('colors.gray.300');

    &.danger {
      background-color: theme('colors.red.300');
      &.outline {
        background-color: transparent;
      }
    }

    .mark {
      opacity: 1;
    }

    &.outline {
      .mark {
        color: theme('colors.gray.300');
      }
    }
  }

  input[type='radio']:disabled ~ .radio-label {
    color: theme('colors.gray.400');
  }

  input[type='radio']:disabled ~ .checkmark {
    background-color: theme('colors.gray.200');
    &.outline {
      border: 2px solid theme('colors.gray.300');
    }
  }
}
</style>
