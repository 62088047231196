<template>
  <div class="flex items-center text-sm">
    <BreadcrumbLink
      v-for="(step, index) in steps"
      :key="index"
      :to="step.path"
      :active="index === steps.length - 1"
      >{{ step.label }}</BreadcrumbLink
    >
  </div>
</template>
<script>
import BreadcrumbLink from '@/components/BreadcrumbLink'
export default {
  components: { BreadcrumbLink },
  props: {
    steps: {
      type: Array,
      default: () => []
    }
  }
}
</script>
