<template>
  <div class="w-full">
    <Input
      v-bind="$attrs"
      :min="min"
      :step="step"
      type="number"
      v-on="$listeners"
    >
      <template #right>
        <div class="text-gray-500 text-sm px-3">
          {{ unit('weight') }}
        </div>
      </template>
    </Input>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    weightUnit: {
      type: String,
      default: null
    },
    step: {
      type: Number,
      default: 0.01
    },
    min: {
      type: Number,
      default: 0
    }
  },
  computed: {
    ...mapGetters('settings', ['unit']),
    inputListeners() {
      return {
        ...this.$listeners,
        input(e) {
          this.$emit('input', e.target.value)
        }
      }
    }
  }
}
</script>
