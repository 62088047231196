export default {
  props: {
    color: {
      type: String,
      default: 'primary'
    },
    shade: {
      type: String,
      default: 'light'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    theme: {
      type: String,
      default: null
    }
  },
  computed: {
    hover() {
      const { color, shade, disabled } = this

      let base, text

      base = parseInt(shade)
      if (typeof base === 'number' && !isNaN(base)) {
        base += 100
        text = 100
      } else {
        base = 'dark'
        text = 'white'
      }

      if (this.theme === 'light') {
        if (this.color === 'primary') {
          return `${disabled ? '' : `hover:bg-primary-light hover:text-white`}
          bg-transparent text-primary-light`
        }

        return `${disabled ? '' : `hover:bg-${color}-500 hover:text-white`}
        bg-${color}-200 text-${color}-600`
      }

      if (this.theme === 'empty') {
        return ''
      }

      return `bg-${color}-${shade}
      text-${isNaN(text) ? text : `${color}-${text}`}
      ${disabled ? '' : `hover:bg-${color}-${base}`}`
    }
  }
}
