<template>
  <Label :color="color" class="text-white" v-text="text" />
</template>

<script>
export default {
  props: {
    value: {
      type: [Boolean, Number],
      default: null
    }
  },
  computed: {
    color() {
      return this.value ? 'bg-green-500' : 'bg-red-500'
    },
    text() {
      return this.value ? this.$t('form.option.yes') : this.$t('form.option.no')
    }
  }
}
</script>
