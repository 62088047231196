export const getName = (vm) => {
  if (vm.$root === vm) {
    return '<App>'
  }

  const name = vm._isVue
    ? vm.$options.name || vm.$options._componentTag
    : vm.name

  return name ? `<${name}>` : null
}

export const isComponent = (vm, name) => {
  return getName(vm) === name
}
