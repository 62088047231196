<template>
  <div
    :class="[theme.input, { 'opacity-50': disabled }]"
    class="flex items-center border rounded border-solid w-full bg-white"
  >
    <div class="flex-grow">
      <Datetime
        v-bind="$attrs"
        :value="value"
        :disabled="disabled"
        :placeholder="$t('form.placeholder.date')"
        :format="resolvedFormat"
        :type="type"
        input-class="p-2 bg-transparent w-full"
        class="theme-primary"
        @input="input"
      />
    </div>
    <div :class="theme.icon" class="pr-2 flex items-center flex-shrink-0">
      <Icon :name="icon" :set="set" class="text-sm" />
    </div>
  </div>
</template>
<script>
import { Datetime } from 'vue-datetime'
import 'vue-datetime/dist/vue-datetime.css'

import moment from 'moment'

export default {
  components: { Datetime },
  props: {
    value: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: null
    },
    icon: {
      type: String,
      default: 'calendar-alt'
    },
    set: {
      type: String,
      default: 'fas'
    },
    format: {
      type: String,
      default: null
    },
    type: {
      type: String,
      default: 'date'
    }
  },
  computed: {
    theme() {
      if (this.color)
        return {
          input: `border-${this.color}-300 text-${this.color}-800 focus:border-${this.color}-500`,
          icon: `text-${this.color}-700`
        }

      return {
        input: 'border-gray-300 text-gray-800 focus:border-primary-light',
        icon: 'text-gray-700'
      }
    },
    resolvedFormat() {
      if (this.format) {
        return this.format
      }

      return this.type === 'date' ? 'MMM dd, yyyy' : 'MMM dd, yyyy HH:mm'
    }
  },
  methods: {
    input(value) {
      this.$emit('input', value ? moment(value).format() : value)
    }
  }
}
</script>

<style lang="scss">
.theme-primary {
  & .vdatetime-popup__header,
  & .vdatetime-calendar__month__day--selected > span > span,
  & .vdatetime-calendar__month__day--selected:hover > span > span {
    background: theme('colors.primary.light');
  }

  & .vdatetime-year-picker__item--selected,
  & .vdatetime-time-picker__item--selected,
  & .vdatetime-popup__actions__button {
    color: theme('colors.primary.light');
  }
}
</style>
