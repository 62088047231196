<template>
  <button
    :class="[
      hover,
      size && `text-${size}`,
      padding,
      {
        rounded: !square,
        'opacity-50 cursor-default pointer-events-none': disabled
      }
    ]"
    :disabled="disabled"
    :type="type"
    class="trans-hover flex items-center focus:outline-none whitespace-no-wrap"
    v-on="$listeners"
    @click="click"
  >
    <div
      v-if="icon && iconPosition === 'left'"
      :class="{ 'mr-2': $slots.default }"
      class="flex items-center justify-center"
    >
      <Icon :name="icon" :set="iconSet" fixed />
    </div>
    <slot />
    <div
      v-if="icon && iconPosition === 'right'"
      :class="{ 'ml-2': $slots.default }"
      class="flex items-center justify-center"
    >
      <Icon :name="icon" :set="iconSet" fixed />
    </div>
  </button>
</template>

<script>
import Hover from '@/mixins/hover'

export default {
  mixins: [Hover],
  props: {
    icon: {
      type: String,
      default: null
    },
    size: {
      type: String,
      default: 'sm'
    },
    padding: {
      type: String,
      default: 'px-3 py-2'
    },
    iconSet: {
      type: String,
      default: 'fas'
    },
    iconPosition: {
      type: String,
      default: 'left'
    },
    square: {
      type: Boolean,
      default: false
    },
    to: {
      type: [String, Object],
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'button'
    }
  },
  methods: {
    click($event) {
      if (this.to) {
        $event.preventDefault()
        this.$router.push(this.to)
      }
    }
  }
}
</script>
